@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'nprogress/nprogress.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

@import 'loading-overlay.css';

#nprogress .bar {
    background: limegreen !important;
}

.container-badge {
    width: 288px;
    height: 450px;
    transition: height 0.3s ease 0s;
}

.container-badge:hover {
    height: 465px;
}

.container-lms {
    width: 288px;
    height: 535px;
    margin: 1rem;
    padding-top: 5px;
    padding-bottom: 5px;
}

.badge-lms {
    width: 270px;
    height: 510px;
    background-color: #1e293b;
    border-radius: 12px;
    margin: 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.badge {
    width: 272px;
    height: 350px;
    background-color: #1e293b;
    border-radius: 12px;
    margin: 0.5rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: height 0.3s ease 0s;
}

.badge:hover {
    height: 370px;
}

button:hover {
    background: none;
}

.hover\:text-accelist-green:hover {
    --tw-text-opacity: 1;
    color: rgb(13 148 136 / var(--tw-text-opacity));
}

.text-accelist-green {
    color: rgb(13 148 136);
}

.bg-accelist-green {
    --tw-bg-opacity: 1;
    background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.text-accelist-font {
    font-weight: 600;
    font-style: normal;
}

.bg-about-us {
    background-image: url('../public/img/bg-tentang-kami.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-program-atas {
    background-image: url('../public/img/bg-deskripsi-per-paket.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-program-bawah {
    background-image: url('../public/img/bg-wujudkan-impian.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-lms-solution {
    background-image: url('../public/img/bg-kami-hadir.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.border-green-bottom {
    border-bottom-width: 4px;
    border-color: #0d9488;
}

.border-green {
    border-width: 4px;
    border-color: #0d9488;
}

.currency {
    position: relative;
    left: 110px; 
    bottom:10px;
    font-weight: semibold;
    font-size: small;
    color: #ffffff;
    align-items: center;
}
.price {
    position: relative;
    top: 0;
    left: 113px;
    font-weight: semibold;
    font-size: x-large;
    color: #ffffff;
  }
  .price::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px; /* Meningkatkan ketebalan garis */
    background-color: red;
    transform: translateY(-50%) rotate(-8deg);
  }
.currency-discount {
    position: relative;
    left: 120px; 
    bottom:10px;
    font-weight: semibold;
    font-size: small;
    color: #EA4040;
    align-items: center;
}
.discount {
    position: relative;
    top: 0;
    left: 124px; 
    font-weight: bold;
    font-size: x-large;
    color: #EA4040;
    margin-right: 5rem;
}
.free {
    position: relative;
    top: 0;
    left: 137px; 
    font-weight: bold;
    font-size: x-large;
    color: #EA4040;
    margin-right: 5rem;
  }

.radio {
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    color: #1e293b;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    margin-top: 16px;
    align-self: start;
}
.radio input[type='radio'] {
    display: none;
}

.radio span {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 4px solid #1e293b;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}

.radio span:after {
    content: '';
    height: 12px;
    width: 12px;
    background: #0d9488;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    border-radius: 50%;
    transition: 300ms ease-in-out 0s;
}

.radio input[type='radio']:checked ~ span:after {
    transform: translate(-50%, -50%) scale(1);
}

.checkbox {
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    color: #1e293b;
    position: relative;
    cursor: pointer;
    padding-left: 35px;
    margin-top: 16px;
    align-self: start;
}
.checkbox input[type='checkbox'] {
    display: none;
}

.checkbox span {
    height: 24px;
    width: 24px;
    border: 4px solid #0d9488;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}

.checkbox span:after {
    content: '';
    height: 24px;
    width: 24px;
    background: #0d9488;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 300ms ease-in-out 0s;
}

.checkbox input[type='checkbox']:checked ~ span:after {
    transform: translate(-50%, -50%) scale(1);
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
   width: 100%;
}
.customDatePickerWidth .react-datepicker__triangle {
    display: none;
  }

@media (max-width: 768px) {
    .freecourse-schedule-web {
        display: none; /* Sembunyikan versi tampilan web */
    }

    .freecourse-schedule-mobile {
        display: block; /* Tampilkan versi tampilan mobile */
    }

    .workshop-schedule-web{
        display: none;
    }

    .workshop-schedule-mobile{
        display: block; 
    }
}

/* Tampilan untuk layar dengan lebar minimal 769px (desktop) */
@media (min-width: 769px) {
    .freecourse-schedule-web {
        display: block; /* Tampilkan versi tampilan web */
    }

    .freecourse-schedule-mobile{
        display: none; /* Sembunyikan versi tampilan mobile */
    }

    .workshop-schedule-web{
        display: block; 
    }

    .workshop-schedule-mobile{
        display: none; 
    }
}